<template>
  <div class="locale-switcher inline-block text-start" ref="localeswitcher">
    <span class="rounded-md shadow-sm">
      <button @click="toggleMenu" class="primary inline-flex justify-center h-11 w-11 px-2 py-3 text-sm font-bold leading-5 transition duration-150 ease-in-out rounded-md" type="button" aria-haspopup="true" aria-expanded="showMenu" aria-controls="headlessui-menu-items-117">
        <span>{{ $i18n.locale.toUpperCase() }}</span>
      </button>
    </span>
    <div v-if="showMenu" class="locale-switcher-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
      <div class="absolute end-0 w-40 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items-117" role="menu">
        <div class="py-1">
          <a v-for="locale in locales" :key="locale.code" href="javascript:void(0)" @click.prevent="setLocale(locale.code)" :class="(locale.code == $i18n.locale) && 'bg-secondary text-primary font-bold'" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-start hover:bg-secondary hover:text-primary" role="menuitem">{{ locale.nativeName }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { locales, changeLocale } from '@/i18n'

export default defineComponent({
  name: 'LocaleSwitcher',
  data: function () {
    return {
      locales: locales,
      showMenu: false
    }
  },
  methods: {
    setLocale (locale: string): void {
      changeLocale(locale)
      this.showMenu = false
    },
    toggleMenu (): void {
      this.showMenu = !this.showMenu
    },
    handleOutsideClick (event: Event): void {
      const el = this.$refs.localeswitcher as HTMLElement
      if (el && !el.contains(event.target as Node)) {
        this.showMenu = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  }
})
</script>

<style scoped>
  .locale-switcher:focus-within .locale-switcher-menu {
    opacity:1;
    transform: translate(0) scale(1);
    visibility: visible;
  }
</style>
