import { Method as apiMethod } from 'axios'

export const productsEndpoints = {
  getProducts: {
    method: 'get' as apiMethod,
    url: '/products'
  },
  getDiscounts: {
    method: 'get' as apiMethod,
    url: '/products/discounts'
  }
}
