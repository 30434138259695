import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen" }
const _hoisted_2 = { class: "app flex-1 pb-6" }
const _hoisted_3 = { class: "fixed z-30 top-20 start-1/2 centralize" }
const _hoisted_4 = {
  key: 0,
  class: "loader fixed flex items-center justify-center w-screen h-screen top-0 start-0 bg-black opacity-50"
}
const _hoisted_5 = { class: "mb-10 text-3xl text-white" }
const _hoisted_6 = {
  key: 1,
  class: "primary rounded max-w-screen h-auto md:h-12 p-3 flex items-center gap-2 fixed bottom-15 z-50 w-max hover:bg-primary start-1/2 transform -translate-x-1/2"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_IndicatorIcon = _resolveComponent("IndicatorIcon")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        onBranchChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.key++))
      }),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.globalMessages, (msg, index) => {
          return (_openBlock(), _createBlock(_component_Alert, {
            key: index,
            type: msg.type,
            title: msg.title,
            index: msg.index,
            isFixed: false
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(msg.text)), 1)
            ]),
            _: 2
          }, 1032, ["type", "title", "index"]))
        }), 128))
      ]),
      (_ctx.$store.state.appLoadingText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.$store.state.appLoadingText)), 1),
              _createVNode(_component_IndicatorIcon, { class: "animate-spin -ms-1 me-3 h-20 w-20 text-white" })
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key })),
      (!_ctx.hiddenCookiesInfo && !_ctx.cookieAccepted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", {
              innerHTML: _ctx.$t('cookies')
            }, null, 8, _hoisted_7),
            _createElementVNode("button", {
              class: "secondary max-h-full",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideCookiesInfo && _ctx.hideCookiesInfo(...args)))
            }, _toDisplayString(_ctx.$t('ok')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Footer, { class: "fixed z-30 bottom-0 bg-white" })
  ]))
}