import { Method as apiMethod } from 'axios'

export const beneficiariesEndpoints = {
  getBeneficiaries: {
    method: 'get' as apiMethod,
    url: '/beneficiaries'
  },
  updateBeneficiary: {
    method: 'put' as apiMethod,
    url: '/beneficiaries'
  },
  confirm: {
    method: 'put' as apiMethod,
    url: '/beneficiaries/confirm'
  },
  deleteBeneficiary: {
    method: 'delete' as apiMethod,
    url: '/beneficiaries'
  }
}
