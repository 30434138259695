import { Method as apiMethod } from 'axios'

export const paymentsEndpoints = {
  getCardsDetails: {
    method: 'get' as apiMethod,
    url: '/payments/cards'
  },
  addCardDetails: {
    method: 'post' as apiMethod,
    url: '/payments/cards'
  },
  updateCard: {
    method: 'put' as apiMethod,
    url: '/payments/cards'
  },
  setDefault: {
    method: 'put' as apiMethod,
    url: '/payments/cards/default'
  },
  deleteCard: {
    method: 'delete' as apiMethod,
    url: '/payments/cards'
  },
  getCurrencies: {
    method: 'get' as apiMethod,
    url: '/payments/currencies'
  }
}
