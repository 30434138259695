<template>
  <div class="flex justify-center" :class="isFixed ? 'fixed z-10 top-5 start-1/2 centralize' : ''">
    <div v-if="alertType === 'info'" class="bg-blue-100 border-t-4 border-blue-500 rounded text-blue-500 px-4 py-3 shadow mb-4" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="stroke-0 fill-current text-blue-500 h-6 w-6 me-4"  xmlns="http://www.w3.org/2000/svg" width="20pt" height="20pt" viewBox="0 0 20 20"><path d="M 10.832031 15 C 10.832031 15.460938 10.460938 15.832031 10 15.832031 C 9.539062 15.832031 9.167969 15.460938 9.167969 15 C 9.167969 14.539062 9.539062 14.167969 10 14.167969 C 10.460938 14.167969 10.832031 14.539062 10.832031 15 Z M 10.832031 15 "/><path d="M 10 12.5 C 9.65625 12.5 9.375 12.21875 9.375 11.875 L 9.375 4.792969 C 9.375 4.445312 9.65625 4.167969 10 4.167969 C 10.34375 4.167969 10.625 4.445312 10.625 4.792969 L 10.625 11.875 C 10.625 12.21875 10.34375 12.5 10 12.5 Z M 10 12.5 "/><path d="M 10 20 C 4.484375 20 0 15.515625 0 10 C 0 4.484375 4.484375 0 10 0 C 15.515625 0 20 4.484375 20 10 C 20 15.515625 15.515625 20 10 20 Z M 10 1.25 C 5.175781 1.25 1.25 5.175781 1.25 10 C 1.25 14.824219 5.175781 18.75 10 18.75 C 14.824219 18.75 18.75 14.824219 18.75 10 C 18.75 5.175781 14.824219 1.25 10 1.25 Z M 10 1.25 "/></svg>
        </div>
        <div class="text-start">
          <p v-if="title" class="font-bold">{{ $t(title) }}</p>
          <slot class="text-sm"></slot>
        </div>
        <div class="ms-auto cursor-pointer" @click="close()">&#10005;</div>
      </div>
    </div>
    <div v-if="alertType === 'success'" class="bg-green-100 border-t-4 border-green-500 rounded text-green-500 px-4 py-3 shadow mb-4" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="stroke-0 fill-current text-green-500 h-6 w-6 me-4"  xmlns="http://www.w3.org/2000/svg" width="20pt" height="20pt" viewBox="0 0 20 20"><path d="M 10 20 C 4.484375 20 0 15.515625 0 10 C 0 4.484375 4.484375 0 10 0 C 15.515625 0 20 4.484375 20 10 C 20 15.515625 15.515625 20 10 20 Z M 10 1.25 C 5.175781 1.25 1.25 5.175781 1.25 10 C 1.25 14.824219 5.175781 18.75 10 18.75 C 14.824219 18.75 18.75 14.824219 18.75 10 C 18.75 5.175781 14.824219 1.25 10 1.25 Z M 10 1.25 "/><path d="M 9.0625 13.332031 C 8.902344 13.332031 8.742188 13.273438 8.621094 13.148438 L 5.914062 10.441406 C 5.667969 10.199219 5.667969 9.800781 5.914062 9.558594 C 6.15625 9.3125 6.550781 9.3125 6.796875 9.558594 L 9.0625 11.824219 L 14.039062 6.847656 C 14.28125 6.605469 14.679688 6.605469 14.921875 6.847656 C 15.167969 7.09375 15.167969 7.488281 14.921875 7.734375 L 9.503906 13.148438 C 9.382812 13.273438 9.222656 13.332031 9.0625 13.332031 Z M 9.0625 13.332031 "/></svg>
        </div>
        <div class="text-start">
          <p v-if="title" class="font-bold">{{ $t(title) }}</p>
          <slot class="text-sm"></slot>
        </div>
        <div class="ms-auto cursor-pointer" @click="close()">&#10005;</div>
      </div>
    </div>
    <div v-if="alertType === 'warning'" class="bg-yellow-100 border-t-4 border-yellow-500 rounded text-yellow-500 px-4 py-3 shadow mb-4" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="stroke-0 fill-current text-yellow-500 h-6 w-6 me-4"  xmlns="http://www.w3.org/2000/svg" width="20pt" height="20pt" viewBox="0 0 20 20"><path d="M 10.832031 15 C 10.832031 15.460938 10.460938 15.832031 10 15.832031 C 9.539062 15.832031 9.167969 15.460938 9.167969 15 C 9.167969 14.539062 9.539062 14.167969 10 14.167969 C 10.460938 14.167969 10.832031 14.539062 10.832031 15 Z M 10.832031 15 "/><path d="M 10 20 C 4.484375 20 0 15.515625 0 10 C 0 4.484375 4.484375 0 10 0 C 15.515625 0 20 4.484375 20 10 C 20 15.515625 15.515625 20 10 20 Z M 10 1.25 C 5.175781 1.25 1.25 5.175781 1.25 10 C 1.25 14.824219 5.175781 18.75 10 18.75 C 14.824219 18.75 18.75 14.824219 18.75 10 C 18.75 5.175781 14.824219 1.25 10 1.25 Z M 10 1.25 "/><path d="M 10 12.292969 C 9.65625 12.292969 9.375 12.011719 9.375 11.667969 L 9.375 10.824219 C 9.375 10.03125 9.878906 9.320312 10.625 9.058594 C 11.621094 8.707031 12.292969 7.644531 12.292969 6.875 C 12.292969 5.609375 11.265625 4.582031 10 4.582031 C 8.734375 4.582031 7.707031 5.609375 7.707031 6.875 C 7.707031 7.21875 7.429688 7.5 7.082031 7.5 C 6.738281 7.5 6.457031 7.21875 6.457031 6.875 C 6.457031 4.921875 8.046875 3.332031 10 3.332031 C 11.953125 3.332031 13.542969 4.921875 13.542969 6.875 C 13.542969 8.265625 12.445312 9.742188 11.042969 10.234375 C 10.792969 10.324219 10.625 10.5625 10.625 10.824219 L 10.625 11.667969 C 10.625 12.011719 10.34375 12.292969 10 12.292969 Z M 10 12.292969 "/></svg>
        </div>
        <div class="text-start">
          <p v-if="title" class="font-bold">{{ $t(title) }}</p>
          <slot class="text-sm"></slot>
        </div>
        <div class="ms-auto cursor-pointer" @click="close()">&#10005;</div>
      </div>
    </div>
    <div v-if="alertType === 'danger'" class="bg-red-100 border-t-4 border-red-500 rounded text-red-500 px-4 py-3 shadow mb-4" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="stroke-0 fill-current text-red-500 h-6 w-6 me-4"  xmlns="http://www.w3.org/2000/svg" width="20pt" height="20pt" viewBox="0 0 20 20"><path d="M 10 20 C 4.484375 20 0 15.515625 0 10 C 0 4.484375 4.484375 0 10 0 C 15.515625 0 20 4.484375 20 10 C 20 15.515625 15.515625 20 10 20 Z M 10 1.25 C 5.175781 1.25 1.25 5.175781 1.25 10 C 1.25 14.824219 5.175781 18.75 10 18.75 C 14.824219 18.75 18.75 14.824219 18.75 10 C 18.75 5.175781 14.824219 1.25 10 1.25 Z M 10 1.25 "/><path d="M 6.90625 13.71875 C 6.746094 13.71875 6.585938 13.65625 6.464844 13.535156 C 6.222656 13.289062 6.222656 12.894531 6.464844 12.652344 L 12.652344 6.464844 C 12.898438 6.21875 13.292969 6.21875 13.535156 6.464844 C 13.78125 6.707031 13.78125 7.101562 13.535156 7.347656 L 7.347656 13.535156 C 7.226562 13.65625 7.066406 13.71875 6.90625 13.71875 Z M 6.90625 13.71875 "/><path d="M 13.09375 13.71875 C 12.933594 13.71875 12.773438 13.65625 12.652344 13.535156 L 6.464844 7.347656 C 6.21875 7.105469 6.21875 6.707031 6.464844 6.464844 C 6.707031 6.21875 7.105469 6.21875 7.347656 6.464844 L 13.535156 12.652344 C 13.78125 12.894531 13.78125 13.292969 13.535156 13.535156 C 13.414062 13.65625 13.253906 13.71875 13.09375 13.71875 Z M 13.09375 13.71875 "/></svg>
        </div>
        <div class="text-start">
          <p v-if="title" class="font-bold">{{ $t(title) }}</p>
          <slot class="text-sm"></slot>
        </div>
        <div class="ms-auto cursor-pointer" @click="close()">&#10005;</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Alert',
  props: {
    type: String,
    title: String,
    index: {
      type: Number,
      default: 0
    },
    isFixed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    alertType: function (): string {
      const t = (this.type) ? this.type.toLowerCase().trim() : ''
      return ((['info', 'success', 'warning', 'danger'].includes(t)) ? t : 'info')
    }
  },
  beforeMount () {
    if (this.type !== 'danger') {
      setTimeout(this.close, 7000)
    }
  },
  emits: ['closed'],
  methods: {
    close () {
      this.$emit('closed')
      this.$store.commit('setGlobalMessages', this.$store.state.globalMessages.filter((message: { index: number }) => message.index !== this.index))
    }
  }
})
</script>

<style lang="scss" scoped>
  .centralize {
    transform: translateX(-50%);
  }
</style>
