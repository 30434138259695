import { createStore } from 'vuex'
import client from 'axios'
import qs from 'qs'
import { VueCookieNext } from 'vue-cookie-next'
import { apiBase, apiEndpoints } from '@/shared/endpoints/api'
import { User } from '@/shared/interfaces/user.model'
import router from '../router'
import { Beneficiary } from '@/shared/interfaces/beneficiary.model'
import { Card } from '@/shared/interfaces/card.model'
import { Settings } from '@/shared/interfaces/settings.model'
import { Discount } from '@/shared/interfaces/discount.model'
import i18n from '@/i18n'

const parseErrorsToInputErrorMessage = (err: any, formName: string | undefined) => {
  if (err.response) {
    const errors = err.response.data.errors
    errors.forEach((error: { message: string, field: string, errorCode: string }) => {
      if (formName) {
        const errorMessage = error.errorCode ? error.errorCode : error.message
        store.state.vuelidateExternalResults[formName] = {
          [error.field]: errorMessage.toString()
        }
      } else {
        store.state.vuelidateExternalResults[error.field] = [error.errorCode ? error.errorCode : error.message]
      }
    })
  }
}

const parseErrorsToTechnicalAlertError = (err: any, message: string) => {
  if (err.response) {
    const errors = err.response.data.errors as { message: string, field: string, errorCode: string }[]
    if (errors && !!errors.find((error: { message: string, field: string, errorCode: string }) => !error.errorCode)) {
      store.commit('setGlobalMessages', [...store.state.globalMessages, {
        type: 'danger',
        title: 'error',
        text: message,
        index: store.state.globalMessages.length
      }])
    }
  }
}

client.defaults.baseURL = apiBase
client.interceptors.request.use(function (config) {
  const token = VueCookieNext.getCookie('token')
  if (token && token.length > 0) {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token
    }
  }
  return config
}, function (error) {
  console.log('error', error)
  return Promise.reject(error)
})

client.interceptors.response.use(function (config) {
  return config
}, function (error) {
  if (
    (error.response.status === 401 || error.response.status === 403) &&
    error.response.config.url.indexOf('logout') < 0 &&
    error.response.config.url.indexOf('login') < 0
  ) store.dispatch('logout')
  if (
    ((error.response.status !== 401 && error.response.status !== 403) ||
    error.response.config.url.indexOf('login') >= 0) &&
    error.response.data.errors && error.response.data.errors.length > 0
  ) {
    const globalErrorsWithCode = error.response.data.errors.filter((err: { field: string, errorCode: string }) => err.field === 'global' && err.errorCode)
    const mappedErrors = globalErrorsWithCode.map((err: { errorCode: string }) => {
      return {
        type: 'danger',
        title: 'error',
        text: err.errorCode,
        index: store.state.globalMessages.length > 0 ? store.state.globalMessages[store.state.globalMessages.length - 1].index + 1 : 0
      }
    })
    store.commit('setGlobalMessages', [...store.state.globalMessages, ...mappedErrors])
  }
  return Promise.reject(error)
})

const store = createStore({
  state: {
    lang: '' as string,
    loggedUser: {} as User,
    accountInterceptor: null as any,
    globalMessages: [] as { type: 'danger' | 'success' | 'info' | 'waring', title?: string, text: string, index: number }[],
    vuelidateExternalResults: {} as any,
    beneficiaries: [] as Beneficiary[],
    beneficiariesCount: 0 as number,
    appLoadingText: null as string | null,
    publicAPIKey: null as string | null,
    privateAPIKey: null as string | null,
    cards: [] as Card[],
    products: [] as any[],
    discounts: [] as Discount[],
    settings: {} as Settings,
    currencies: [] as string[]
  },
  mutations: {
    setLang: (state, newLang) => { state.lang = newLang },
    setLoggedUser: (state, loggedUser: User) => { state.loggedUser = loggedUser },
    setAccountInterceptor: (state, accountInterceptor: any) => { state.accountInterceptor = accountInterceptor },
    setGlobalMessages: (state, globalMessages: any) => { state.globalMessages = globalMessages },
    setVuelidateExternalResults: (state, vuelidateExternalResults: any) => { state.vuelidateExternalResults = vuelidateExternalResults },
    setBeneficiaries: (state, beneficiaries: Beneficiary[]) => { state.beneficiaries = beneficiaries },
    setBeneficiariesCount: (state, count: number) => { state.beneficiariesCount = count },
    setAppLoadingText: (state, appLoadingText: string | null) => { state.appLoadingText = appLoadingText },
    setPublicAPIKey: (state, publicAPIKey: string | null) => { state.publicAPIKey = publicAPIKey },
    setPrivateAPIKey: (state, privateAPIKey: string | null) => { state.privateAPIKey = privateAPIKey },
    setCards: (state, cards: Card[]) => { state.cards = cards },
    setProducts: (state, products: any[]) => { state.products = products },
    setDiscounts: (state, discounts: Discount[]) => { state.discounts = discounts },
    setSettings: (state, settings: Settings) => { state.settings = settings },
    setCurrencies: (state, currencies: string[]) => { state.currencies = currencies }
  },
  actions: {
    setToken: (context, token: string) => {
      VueCookieNext.setCookie('token', token, { path: '/', domain: location.hostname })
      context.dispatch('getLoggedUser')
    },
    removeToken: (context) => {
      VueCookieNext.removeCookie('token', { path: '/', domain: location.hostname })
      VueCookieNext.removeCookie('missingCardsClosed')
      context.commit('setLoggedUser', null)
      router.push('/auth')
    },
    login: async (context, { email, password, remember }): Promise<string> => {
      const data = {
        email,
        password
      }
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.login.method,
          url: apiEndpoints.login.url,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data)
        }).then(async resp => {
          const token = resp.data.result.token
          context.dispatch('setToken', token)
          resolve(token)
        }).catch(err => {
          context.dispatch('removeToken')
          parseErrorsToInputErrorMessage(err, undefined)
          parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
          console.log(err)
          reject(err)
        })
      })
    },
    logout: async (context) => {
      const userId = context.state.loggedUser ? context.state.loggedUser._id : null
      await client.post(apiEndpoints.logout.url, { body: { userId } }).then(() => {
        context.dispatch('removeToken')
      }).catch(() => {
        context.dispatch('removeToken')
      })
    },
    getLoggedUser: async (context, token) => {
      await client.get(apiEndpoints.me.url).then(response => {
        context.commit('setLoggedUser', response.data.result)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setLoggedUser', null)
      })
    },
    getSettings: async (context) => {
      await client.get(apiEndpoints.getSettings.url).then(response => {
        context.commit('setSettings', response.data.result)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setSettings', null)
      })
    },
    generate: async (context, { typeOfKey, generatePublic }) => {
      context.commit('setAppLoadingText', 'loader.gettingData')
      try {
        const response = await client.post(apiEndpoints.generate.url, { typeOfKey })
        if (generatePublic) {
          context.commit('setPublicAPIKey', response.data.result)
        } else {
          context.commit('setPrivateAPIKey', response.data.result)
        }
        context.commit('setAppLoadingText', null)
      } catch (error) {
        console.error('generate error:', error)
        context.commit('setAppLoadingText', null)
        throw error
      }
    },
    register: async (context, { email, password, passwordConfirm, companyName, shouldRedirect = true, token }, formName = 'registerForm') => {
      const data = {
        email,
        password,
        password_confirm: passwordConfirm,
        companyName,
        token
      }
      await client({
        method: apiEndpoints.register.method,
        url: apiEndpoints.register.url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data)
      }).then(resp => {
        if (shouldRedirect) router.push('/register-success')
        return resp.data
      }).catch(err => {
        parseErrorsToInputErrorMessage(err, formName)
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        console.log(err)
      })
    },
    activate: (context, data: { email: string, token: string }): Promise<string> => {
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.activate.method,
          url: apiEndpoints.activate.url,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + data.token
          },
          data: qs.stringify({ email: data.email })
        }).then(() => {
          resolve('')
        }).catch(err => {
          parseErrorsToTechnicalAlertError(err, 'technical_problems_contact_us')
          console.log(err)
          reject(err)
        })
      })
    },
    forgot: async (context, email) => {
      const data = {
        email
      }
      await client({
        method: apiEndpoints.forgot.method,
        url: apiEndpoints.forgot.url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data)
      }).then(resp => {
        const token = resp.data.access_token
      }).catch(err => {
        parseErrorsToInputErrorMessage(err, undefined)
        console.log(err)
      })
    },
    // eslint-disable-next-line
    reset: async (context, { email, token, password, password_confirm }, formName = 'resetForm') => {
      const data = {
        email,
        password,
        password_confirm
      }
      await client({
        method: apiEndpoints.reset.method,
        url: apiEndpoints.reset.url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + token
        },
        data: qs.stringify(data)
      }).then(resp => {
        router.push('/auth')
      }).catch((err: any) => {
        parseErrorsToInputErrorMessage(err, formName)
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        console.log(err)
      })
    },
    getBeneficiaries: async (context, filters) => {
      const localFilters = localStorage.getItem('filters')
      if (localFilters) filters = { ...filters, ...JSON.parse(localFilters) }
      delete filters.sortByColumn
      await client.get(apiEndpoints.getBeneficiaries.url, { params: filters }).then(response => {
        context.commit('setBeneficiaries', response.data.result)
        context.commit('setBeneficiariesCount', response.data.count)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    updateBeneficiary: (context, data) => {
      return client.put(apiEndpoints.updateBeneficiary.url, data).then(response => {
        context.commit('setBeneficiaries', response.data.result)
        context.commit('setBeneficiariesCount', response.data.count)
        return response
      }, (err) => {
        parseErrorsToInputErrorMessage(err, 'item')
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        throw err
      })
    },
    confirm: async (context, _id) => {
      await client.put(apiEndpoints.confirm.url, { _id }).then(response => {
        const confirmedBeneficiary = context.state.beneficiaries.find((beneficiary: Beneficiary) => beneficiary._id === _id)
        if (confirmedBeneficiary) confirmedBeneficiary.status = 'confirmed'
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    deleteBeneficiary: async (context, _id) => {
      await client.delete(apiEndpoints.deleteBeneficiary.url, { data: { _id } }).then(response => {
        context.commit('setBeneficiaries', response.data.result)
        context.commit('setBeneficiariesCount', response.data.count)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    updateUser: async (context, userData = null) => {
      context.commit('setAppLoadingText', 'loader.savingUser')
      let data = userData
      if (!data) data = context.state.loggedUser
      await client.put(apiEndpoints.updateUser.url, data).then(response => {
        context.commit('setLoggedUser', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    updateSettings: async (context, settingData) => {
      context.commit('setAppLoadingText', 'loader.settingData')
      await client.put(apiEndpoints.updateSettings.url, settingData).then(response => {
        context.commit('setSettings', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    getCardsDetails: async (context) => {
      context.commit('setAppLoadingText', 'loader.gettingCard')
      await client.get(apiEndpoints.getCardsDetails.url).then(response => {
        context.commit('setCards', response.data.result)
        context.commit('setAppLoadingText', null)
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    fetchCurrencies: async (context) => {
      context.commit('setAppLoadingText', 'loader.gettingData')
      await client.get(apiEndpoints.getCurrencies.url)
        .then(response => {
          context.commit('setCurrencies', response.data.result)
          context.commit('setAppLoadingText', null)
        })
        .catch((err) => {
          parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
          context.commit('setAppLoadingText', null)
        })
    },
    addCardDetails: async (context, { tokenId, last4, currency }: { tokenId: string, last4: string, currency: string }) => {
      context.commit('setAppLoadingText', 'loader.settingCard')
      await client.post(apiEndpoints.addCardDetails.url, { tokenId, last4, currency }).then(response => {
        context.commit('setAppLoadingText', null)
        context.dispatch('getCardsDetails')
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    setDefaultCard: async (context, _id: string) => {
      context.commit('setAppLoadingText', 'loader.settingCard')
      await client.put(apiEndpoints.setDefault.url, { _id }).then(response => {
        context.commit('setAppLoadingText', null)
        context.dispatch('getCardsDetails')
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    deleteCard: async (context, _id) => {
      context.commit('setAppLoadingText', 'loader.removingCard')
      await client.delete(apiEndpoints.deleteCard.url, { data: { _id } }).then(response => {
        context.commit('setAppLoadingText', null)
        context.dispatch('getCardsDetails')
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    sendContactForm: async (context, { form, shouldRedirect = true, formName = 'contactForm' }) => {
      return new Promise((resolve, reject) => {
        client.post(apiEndpoints.sendContactForm.url, form).then(response => {
          if (shouldRedirect) router.push('/ContactSuccess')
          resolve(response.data)
        }).catch((err) => {
          parseErrorsToInputErrorMessage(err, formName)
          parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
          reject(err)
        })
      })
    },
    getProducts: async (context, filters) => {
      context.commit('setAppLoadingText', 'loader.gettingProducts')
      await client.get(apiEndpoints.getProducts.url, { params: filters }).then(response => {
        context.commit('setProducts', response.data.result)
        context.commit('setAppLoadingText', null)
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    getDiscounts: async (context, filters) => {
      context.commit('setAppLoadingText', 'loader.gettingDiscounts')
      await client.get(apiEndpoints.getDiscounts.url, { params: filters }).then(response => {
        context.commit('setDiscounts', response.data.result)
        context.commit('setAppLoadingText', null)
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    verifyAcceptProfit: async (context, { _id, token }) => {
      return new Promise((resolve, reject) => {
        const locale = i18n.global.locale
        client.post(apiEndpoints.verifyAcceptProfit.url, {
          locale,
          _id,
          token,
          refresh_url: `${location.origin}/#/auth/beneficiary/${_id}/${token}`,
          return_url: `${location.origin}/#/auth/beneficiary-status`
        }).then(response => {
          resolve(response.data)
          if (response.data.result && response.data.result.url) window.location.href = response.data.result.url
        }).catch((err) => {
          parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
          reject(err)
        })
      })
    },
    getStatus: async (context, { _id, token }) => {
      return new Promise((resolve, reject) => {
        console.log('_id, token', _id, token)
        client.get(apiEndpoints.getStatus.url, {
          params: {
            _id,
            token
          }
        }).then(response => {
          resolve(response.data)
        }).catch((err) => {
          parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
          reject(err)
        })
      })
    }
  },
  modules: {
  }
})

export default store
