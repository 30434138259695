<template>
  <div class="header flex items-center bg-backgroundColor h-17 fixed top-0 w-screen py-0.5 ps-0.5 pe-0.5 z-20 md:ps-7.5 md:pe-4 gap-1">
    <div class="logo h-full flex items-center lg:me-15 xl:me-38">
      <img class="h-full me-2.5 block lg:hidden cursor-pointer object-contain" src="@/assets/logomobile.png" alt="" @click="$router.push('/')">
      <img class="h-full me-2.5 hidden lg:block cursor-pointer object-contain" src="@/assets/logomobile.png" alt="" @click="$router.push('/')">
      <img v-if="$store.state.loggedUser" class="h-full me-2.5 hidden lg:block py-3.5 cursor-pointer object-contain" src="@/assets/logo.png" alt="" @click="$router.push('/')">
      <img v-if="!$store.state.loggedUser" class="h-full me-2.5 hidden md:block py-3.5 cursor-pointer object-contain" src="@/assets/logo.png" alt="" @click="$router.push('/')">
    </div>

    <!-- Navigation for Mobile -->
    <div v-if="showSidebar" class="w-full fixed top-17 left-0 bg-gray-100 z-50 border-r overflow-y-visible transition-transform transform translate-x-0 md:translate-x-[-100%]">
      <router-link :class="{'font-bold': $route.path.startsWith('/app')}" to="/app" class="block py-3 border-b hover:bg-primary hover:text-white menuLink" v-if="$store.state.loggedUser" active-class="active-link" @click="showSidebar = false">{{ $t('nav.account') }}</router-link>
      <router-link :class="{'font-bold': $route.path === '/'}" to="/" class="block py-3 border-b hover:bg-primary hover:text-white menuLink" active-class="active-link" @click="showSidebar = false">{{ $t('nav.home') }}</router-link>
      <router-link :class="{'font-bold': $route.path === '/docs'}" to="/docs" class="block py-3 border-b hover:bg-primary hover:text-white menuLink" active-class="active-link" @click="showSidebar = false">{{ $t('nav.docs') }}</router-link>
      <router-link :class="{'font-bold': $route.path === '/about'}" to="/about" class="block py-3 border-b hover:bg-primary hover:text-white menuLink" active-class="active-link" @click="showSidebar = false">{{ $t('nav.about') }}</router-link>
      <router-link :class="{'font-bold': $route.path === '/pricing'}" to="/pricing" class="block py-3 border-b hover:bg-primary hover:text-white menuLink" active-class="active-link" @click="showSidebar = false">{{ $t('nav.pricing') }}</router-link>
      <router-link :class="{'font-bold': $route.path === '/contact'}" to="/contact" class="block py-3 border-b hover:bg-primary hover:text-white menuLink" active-class="active-link" @click="showSidebar = false">{{ $t('nav.contactNav') }}</router-link>
    </div>

    <!-- Normal navigation for larger screens -->
    <div class="h-full hidden xl:flex items-center gap-5 text-lg font-bold">
      <router-link :class="{'text-xl text-primaryActive': $route.path.startsWith('/app')}" to="/app" class="hover:text-primaryActive" v-if="$store.state.loggedUser">{{ $t('nav.account') }}</router-link>
      <router-link :class="{'text-xl text-primaryActive': $route.path === '/'}" to="/" class="hover:text-primaryActive">{{ $t('nav.home') }}</router-link>
      <router-link :class="{'text-xl text-primaryActive': $route.path === '/docs'}" to="/docs" class="hover:text-primaryActive">{{ $t('nav.docs') }}</router-link>
      <router-link :class="{'text-xl text-primaryActive': $route.path === '/about'}" to="/about" class="hover:text-primaryActive">{{ $t('nav.about') }}</router-link>
      <router-link :class="{'text-xl text-primaryActive': $route.path === '/pricing'}" to="/pricing" class="hover:text-primaryActive">{{ $t('nav.pricing') }}</router-link>
      <router-link :class="{'text-xl text-primaryActive': $route.path === '/contact'}" to="/contact" class="hover:text-primaryActive">{{ $t('nav.contactNav') }}</router-link>
    </div>

    <div class="rightIcons h-full flex items-center ms-auto gap-1">
      <div class="xl:hidden hover:text-primaryActive" :class="{'text-primaryActive': showSidebar}">
        <button ref="menuButton" @click="showSidebar = !showSidebar">
          Menu
        </button>
      </div>
      <LocaleSwitcher></LocaleSwitcher>
      <div class="flex items-center gap-1" v-if="($store.state.loggedUser && $store.state.loggedUser.email)">
        <router-link
          v-if="
            $router.currentRoute.value.matched.length > 0 &&
            $router.currentRoute.value.matched[0].name === 'Account' &&
            $store.state.loggedUser &&
            $store.state.loggedUser.companyName
          "
          class="primary w-11 h-11 py-2 flex justify-center items-center rounded-md"
          to="/app/settings"
        >
          <AccountIcon></AccountIcon>
        </router-link>
        <button class="primary p-0 w-11 h-11" @click="logout">
          <LogoutIcon class="text-white"></LogoutIcon>
        </button>
      </div>
      <div v-else-if="$router.currentRoute.value.matched.length > 0 && $router.currentRoute.value.matched[0].name !== 'Auth'">
        <div class="flex gap-1">
          <router-link
            class="primary p-3 h-11 inline-flex items-center justify-center rounded-md font-bold text-xs md:text-base"
            to="/auth/signup"
          >
            {{ $t('auth.signup') }}
          </router-link>
          <router-link
            class="primary p-3 h-11 inline-flex items-center justify-center rounded-md font-bold text-xs md:text-base"
            to="/auth"
          >
            {{ $t('auth.signin') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted, Ref } from 'vue'
import LocaleSwitcher from '@/shared/components/LocaleSwitcher.vue'
import LogoutIcon from '@/shared/components/base-icon/icons/LogoutIcon.vue'
import AccountIcon from '@/shared/components/base-icon/icons/AccountIcon.vue'
import { VueCookieNext } from 'vue-cookie-next'

export default defineComponent({
  name: 'Header',
  components: {
    LocaleSwitcher,
    LogoutIcon,
    AccountIcon
  },
  emits: ['branchChanged'],
  methods: {
    logout: function (): void {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/auth')
      })
    },
    handleOutsideClick (event: Event): void {
      const el = this.$refs.menuButton as HTMLElement
      if (el && !el.contains(event.target as Node) && (event.target as any).className !== 'multiselect__select') {
        this.showSidebar = false
      }
    }
  },
  beforeMount () {
    if (VueCookieNext.getCookie('token')) this.$store.dispatch('getLoggedUser')
  },
  mounted () {
    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  setup () {
    const showSidebar = ref(false)
    const menuButton: Ref<HTMLElement | null> = ref(null)
    return {
      showSidebar,
      menuButton
    }
  }
})
</script>
