<template>
  <div class="flex flex-col min-h-screen">
    <div class="app flex-1 pb-6">
      <Header @branchChanged="key++"></Header>
      <div class="fixed z-30 top-20 start-1/2 centralize">
        <Alert v-for="(msg, index) in $store.state.globalMessages" :key="index" :type="msg.type" :title="msg.title" :index="msg.index" :isFixed="false"><p>{{ $t(msg.text) }}</p></Alert>
      </div>
      <div class="loader fixed flex items-center justify-center w-screen h-screen top-0 start-0 bg-black opacity-50" v-if="$store.state.appLoadingText">
        <div>
          <div class="mb-10 text-3xl text-white">{{ $t($store.state.appLoadingText) }}</div>
          <IndicatorIcon class="animate-spin -ms-1 me-3 h-20 w-20 text-white" ></IndicatorIcon>
        </div>
      </div>
      <router-view :key="key"/>
      <div
        v-if="!hiddenCookiesInfo && !cookieAccepted"
        class="primary rounded max-w-screen h-auto md:h-12 p-3 flex items-center gap-2 fixed bottom-15 z-50 w-max hover:bg-primary start-1/2 transform -translate-x-1/2"
      >
        <div v-html="$t('cookies')"></div>
        <button class="secondary max-h-full" @click="hideCookiesInfo">{{ $t('ok') }}</button>
      </div>
    </div>
    <Footer class="fixed z-30 bottom-0 bg-white"></Footer>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { changeLocale } from '@/i18n'
import Alert from '@/shared/components/Alert.vue'
import Header from '@/shared/components/Header.vue'
import Footer from '@/shared/components/Footer.vue'
import IndicatorIcon from '@/shared/components/base-icon/icons/IndicatorIcon.vue'
import { VueCookieNext } from 'vue-cookie-next'

export default defineComponent({
  name: 'App',
  components: {
    Alert,
    Header,
    IndicatorIcon,
    Footer
  },
  data: function () {
    return {
      key: 0 as number,
      cookieAccepted: false
    }
  },
  computed: {
    hiddenCookiesInfo () {
      return !!VueCookieNext.getCookie('hiddenCookiesInfo')
    }
  },
  methods: {
    hideCookiesInfo () {
      this.cookieAccepted = true
      VueCookieNext.setCookie('hiddenCookiesInfo', 'true')
    }
  },
  beforeMount () {
    const locale = String(localStorage.getItem('language'))
    if (localStorage.getItem('language')) changeLocale(locale)
  }
})
</script>

<style lang="scss">
@import "./styles/fonts.scss";
@import "./styles/main.scss";

.app {
  padding-top: calc(4.375rem - 1px);
  .loader {
    z-index: 51;
  }
}

.centralize {
  transform: translateX(-50%);
}
</style>
