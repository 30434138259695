<template>
  <footer>
    <p class="text-center w-screen h-6 border-t border-backgroundColor text-gray-600">
      © 2023 Recommend. All rights reserved. |
      <router-link to="/TermsOfService" class="text-blue-500 hover:underline">Terms of Service</router-link>
    </p>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer',
  components: {
  }
})
</script>
