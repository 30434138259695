import { authEndpoints } from './auth'
import { beneficiariesEndpoints } from './beneficiaries'
import { paymentsEndpoints } from './payments'
import { productsEndpoints } from './products'
import { settingsEndpoints } from './settings'

export const apiBase = location.origin + '/api' || process.env.VUE_APP_API_BASE || 'base'

export const apiEndpoints = {
  ...authEndpoints,
  ...beneficiariesEndpoints,
  ...paymentsEndpoints,
  ...productsEndpoints,
  ...settingsEndpoints
}
