import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
  .component('multiselect', Multiselect)
  .component('VueDatePicker', VueDatePicker)
  .use(store)
  .use(router)
  .use(VueCookieNext)
  .use(i18n)
  .use(VueReCaptcha, { siteKey: '6Le-HBUpAAAAAMDZMLLWsiuVrh9qu6_ZnZj1Knmr', loaderOptions: {} })
  .mount('#app')
